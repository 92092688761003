<template>
  <div class="layer_switch">
    <ul
      id="layer-control"
      @click="layer_hide = !layer_hide"
      :class="{ widthActive: layer_hide }"
    >
      <li v-if="layer_hide == false" :class="{ active: switchLayer == 1 }">
        <span v-if="switchLayer == 0">地貌图</span>
        <span v-if="switchLayer == 1">矢量图</span>
        <span v-if="switchLayer == 2">ESRI影像图</span>
<!--        <span v-if="switchLayer == 3">高德影像图</span>-->
        <span v-if="switchLayer == 4">天地图影像图</span>
        <img
          v-if="switchLayer == 0"
          src="@/assets/mapimages/maptools/map3.jpg"
          alt
        />
        <img
          v-if="switchLayer == 1"
          src="@/assets/mapimages/maptools/map1.jpg"
          alt
        />
        <img
          v-if="switchLayer == 2 || switchLayer == 3 || switchLayer == 4"
          src="@/assets/mapimages/maptools/map2.jpg"
          alt
        />
      </li>
      <li @click="switchLayer = 1" :class="{ active: switchLayer == 1 }">
        <span>矢量图</span>
        <img src="@/assets/mapimages/maptools/map1.jpg" alt />
      </li>
      <li @click="switchLayer = 0" :class="{ active: switchLayer == 0 }">
        <span>地貌图</span>
        <img src="@/assets/mapimages/maptools/map3.jpg" alt />
      </li>
      <li @click="switchLayer = 2" :class="{ active: switchLayer == 2 }">
        <span>ESRI影像图</span>
        <img src="@/assets/mapimages/maptools/map2.jpg" alt />
      </li>
      <li @click="switchLayer = 4" :class="{ active: switchLayer == 4 }">
        <span>天地图影像图</span>
        <img src="@/assets/mapimages/maptools/map2.jpg" alt />
      </li>
<!--      <li @click="switchLayer = 3" :class="{ active: switchLayer == 3 }">-->
<!--        <span>高德影像图</span>-->
<!--        <img src="@/assets/mapimages/maptools/map2.jpg" alt />-->
<!--      </li>-->
    </ul>
  </div>
</template>

<script>
export default {
  name: "layerSwitch",
  data() {
    return {
      switchLayer: 1,
      showlayer: 1,
      layer_hide: false,
      layerStyleControlShow: false,
    };
  },
  computed: {},
  methods: {},

  watch: {
    switchLayer(news) {
      this.$emit("switchLayer", news);
    },
  },
};
</script>

<style scoped lang='less'>
#layer-control {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 5px;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  // transform: translateX(66%);
  display: flex;
  width: 93px;
  overflow: hidden;
  li:hover {
    border-color: #3385ff;
    span {
      background: rgba(23, 152, 240, 1);
    }
  }

  .active {
    border-color: #3385ff;
  }

  .active span {
    background: rgba(23, 152, 240, 1);
  }

  li {
    float: left;
    width: 85px;
    height: 60px;
    position: relative;
    flex-shrink: 0;
    margin-left: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
    overflow: hidden;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 12px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      padding: 3px 5px;
      border-radius: 6px 0px 0px 0px;
    }
  }

  li:first-child {
    margin-left: 0;
  }
}
.layer-style-container {
  width: 50+176px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #444;
  display: flex;
  transform: translateX(100%);
  transition: 0.5s ease;
  z-index: 101;

  #layer-style-close {
    background: #353535;
    width: 50px;
    cursor: pointer;
  }

  #layer-right {
    padding: 10px;
    width: 176px;
  }

  .title {
    width: 100%;
    height: 32px;
    background: rgba(53, 53, 53, 1);
    border-radius: 2px;
    color: #fff;
    text-align: center;
    line-height: 32px;
  }

  .checkbox {
    display: flex;
    margin-right: 8px;
    cursor: pointer;

    i {
      background: #d3cccc;
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
  }

  input {
    display: none;
  }

  ul {
    margin-top: 10px;

    li + li {
      border-top: 1px solid #666;
    }

    li {
      display: flex;
      color: #fff;
      font-size: 14px;
      padding: 10px 5px;
    }
  }
}
.widthActive {
  width: 385px !important;
}
.layer-show {
  transform: translateX(0) !important;
}
.d-flex {
  display: flex;
}
.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.check-all {
  height: 46px;
  border: 1px solid rgba(49, 49, 49, 1);
  background: linear-gradient(
    0deg,
    rgba(67, 67, 67, 1) 0%,
    rgba(55, 55, 55, 1) 100%
  );
  border-radius: 4px;
  align-items: center;
  margin: 0 -5px;
  padding: 0 10px;
  color: #fff;
}
</style>

