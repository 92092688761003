import http from '../../api/http';
import store from '../../store/index'

var exportData = function(exportType, param) {
    if (param.wheres && typeof param.wheres == "object") {
        param.wheres = JSON.stringify(param.wheres);
    }
    http
        .post("/api/" + exportType + "/Export", param, "正在导出数据....")
        .then((result) => {
            if (!result.status) {
                return this.$error(result.message);
            }
            var timestamp = new Date().getTime();
            let path = "/api/" + exportType + "/DownLoadFile";
            path = path[0] == "/" ? path.substring(1) : path;
            download(
                http.ipAddress + path + "?path=" + result.data,
                timestamp + ".xlsx"
            );
        });
}

function download(url, fileName) {
    //下载导出的文件
    let xmlResquest = new XMLHttpRequest();
    xmlResquest.open("GET", url, true);
    xmlResquest.setRequestHeader("Content-type", "application/json");
    xmlResquest.setRequestHeader(
        "Authorization",
        store.getters.getToken()
    );
    let elink = document.createElement("a");
    xmlResquest.responseType = "blob";
    xmlResquest.onload = function(oEvent) {
        console.log(oEvent);
        if (xmlResquest.status != 200) {
            this.$error("下载文件出错了..");
            return;
        }
        let content = xmlResquest.response;
        elink.download = fileName; //+".xlsx";
        // elink.style.display = "none";
        let blob = new Blob([content]);
        elink.href = URL.createObjectURL(blob);
        // document.body.appendChild(elink);
        elink.click();
        //  document.body.removeChild(elink);
    };
    xmlResquest.send();
}

export {
    exportData
}